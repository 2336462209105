var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-10 pb-0"},[_c('h6',[(!_vm.model.idDistributionBoard)?_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-3-box-outline')}}):_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-1-box-outline')}}),_vm._v(" Sucursales del cuadro de distribución "),(!_vm.model.idDistributionBoard == '')?_c('span',[_vm._v(" | "+_vm._s(this.model.name)+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-lazy',{attrs:{"options":{
          threshold: 0.5,
        },"min-height":"100","transition":"fade-transition"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"idBranchOffice","height":"auto","dense":"","fixed-header":"","hide-selected":"","single-select":false,"search":_vm.searchBranch,"footer-props":{
            'items-per-page-text': _vm.$t('table.item_table'),
            'items-per-page-all-text': _vm.$t('table.all'),
            'items-per-page-options': [-1],
          },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"show-select":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row pt-0"},[_c('div',{staticClass:"col-md-6 pt-0"}),_c('div',{staticClass:"col-md-6 pt-0"},[_c('v-text-field',{staticClass:"pl-1",attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.searchBranch),callback:function ($$v) {_vm.searchBranch=$$v},expression:"searchBranch"}})],1)])]},proxy:true},{key:`item.data-table-select`,fn:function({ isSelected, select }){return [_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"input":function($event){return select($event)}}})]}}],null,true),model:{value:(_vm.model.branchOffices),callback:function ($$v) {_vm.$set(_vm.model, "branchOffices", $$v)},expression:"model.branchOffices"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }